import { NorwegianId } from 'norwegian-national-id-validator';
import { lazy } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Formats a number as a price string in Norwegian currency format.
 * @param {number} sum - The number to be formatted as a price.
 * @param {number} precision - The number of decimal places to display.
 * @returns {string} The formatted price string with "kr" suffix.
 */
const formatPrice = (sum, precision) => {
  const formatter = new Intl.NumberFormat('nb-NO', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  });
  return `${formatter.format(sum)} kr`;
};

/** @deprecated */
function useQueryParams(param) {
  return useSearchParams()[0].get(param);
}

function lazyComponentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          lazyComponentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject,
          );
        }, 500);
      });
  });
}

function inputValidation(
  value,
  lib,
  numberMax = null,
  numberMin = null,
  email = null,
  phoneNumber = null,
  pattern = null,
  ssn = null,
) {
  const err = null;
  if (value) {
    if (numberMax || numberMin) {
      return validateMinMaxNumberInput(value, numberMax, numberMin, lib);
    }
    if (email) {
      return validateEmailInput(value, lib);
    }
    if (phoneNumber) {
      return validatePhoneNumberInput(value, lib, pattern);
    }
    if (ssn) {
      return validateSsnInput(value, lib);
    }
  }
  return err;
}

function validateEmailInput(value, lib) {
  const err = null;
  if (
    String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  ) {
    return err;
  } else {
    const t = lib ? lib.email : `Provide correct email address`;
    return {
      isError: true,
      text: t,
    };
  }
}

function validatePhoneNumberInput(value, lib, pattern = null) {
  const t = lib
    ? lib.phone_number
    : `Expected to get 8 digit long phone number`;
  if (value && value.length !== 8) {
    return {
      isError: true,
      text: t,
    };
  }
  if (value && pattern !== null) {
    const phoneNumberPatter = new RegExp(pattern);
    if (phoneNumberPatter.test(value) === false) {
      return {
        isError: true,
        text: t,
      };
    }
  }
  return null;
}

function validateMinMaxNumberInput(
  value,
  numberMax = null,
  numberMin = null,
  lib = null,
) {
  let err = null;
  const numberValue = Number(value);
  if (
    numberMax !== null &&
    numberMin !== null &&
    (numberValue < numberMin || numberValue > numberMax)
  ) {
    const t = lib
      ? lib.range
      : `Value should be between ${formatPrice(
          numberMin,
          0,
          'right',
        )} and ${formatPrice(numberMax, 0, 'right')}`;
    err = {
      isError: true,
      text: t,
    };
  }
  if (
    numberMax !== null &&
    numberMin !== null &&
    numberValue >= numberMin &&
    numberValue <= numberMax
  ) {
    err = null;
  }
  if (numberMax !== null && numberMin === null && numberValue > numberMax) {
    const t = lib
      ? lib.max
      : `Value should be greater than ${formatPrice(numberMax, 0, 'right')}`;
    err = {
      isError: true,
      text: t,
    };
  }
  if (numberMax !== null && numberMin === null && numberValue <= numberMax) {
    err = null;
  }
  if (numberMax === null && numberMin !== null && numberValue < numberMin) {
    const t = lib
      ? lib.min
      : `Value cannot be lower than ${formatPrice(numberMin, 0, 'right')}`;
    err = {
      isError: true,
      text: t,
    };
  }
  if (numberMax === null && numberMin !== null && numberValue >= numberMin) {
    err = null;
  }
  return err;
}

function validateSsnInput(value, lib) {
  const validation = NorwegianId(value);
  if (!validation.isValid()) {
    const errorText = lib ? lib.ssn : `Provide correct SSN`;
    return {
      isError: true,
      text: errorText,
    };
  }
  return null;
}

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }
      throw error;
    }
  });

function getDaysCountBetween(start, last) {
  const date1 = new Date(start);
  const date2 = new Date(last);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffTime = date2.getTime() - date1.getTime();
  return Math.round(diffTime / oneDay);
}

export {
  formatPrice,
  getDaysCountBetween,
  inputValidation,
  lazyComponentLoader,
  lazyWithRetry,
  useQueryParams,
  validateMinMaxNumberInput,
};

/**
 * @param {number} ms
 * @returns {Promise<void>}
 */
export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
